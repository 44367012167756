import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const KulinarnyPage = () => (
  <Layout>
    <SEO title="Kulinarny Weekend w Gdańsku" />
    <section className="single-trip-description">
      <div className="wrap">
        <div className="negative-margin-left negative-margin-photo">
          <img src={`/static/kulinarny-weekend.jpg`} alt="" />
        </div>
        <div>
          <h3>ZRÓBMY SOBIE UCZTĘ NAD MORZEM </h3>
          <h1>Kulinarny Weekend w Gdańsku!</h1>
          <p>
            Serdecznie zapraszamy na weekend degustacyjny do Gdańska.
            Odwiedzicie najlepsze restauracje w mieście z oryginalnymi polskimi
            potrawami w towarzystwie uroczych widoków nad Zatoką Gdańską. Ze
            względu na to, że sami jesteśmy łasuchami jest to jedna z naszych
            ulubionych ofert jakie przedstawiamy naszym klientom. Poczujecie
            przyjazną atmosferę Gdańska, tętniące życiem ulice i piękną
            architekturę. Przede wszystkim spróbujecie tradycyjnej kuchni,
            gdańskiego alkoholu oraz odpoczniecie od codziennych obowiązków.
          </p>
        </div>
        <div className="trip-plan">
          <h4>W programie proponujemy:</h4>
          <div className="day">
            <div className="day-number">Dzień 1</div>
            <div className="day-plan">
              <ul>
                <li>Transfer z lotniska / dworca PKP</li>
                <li>Zakwaterowanie w hotelu / pensjonacie / apartamencie</li>
                <li>Degustacja piwa w lokalnych browarach</li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 2</div>
            <div className="day-plan">
              <ul>
                <li>Zwiedzanie szlakiem kuchni polskiej i gdańskiej</li>
                <li>Lunch na szczycie najwyższego budynku w mieście</li>
                <li>
                  Wizyta w restauracji z tradycyjnym alkoholem z 24 karatowymi
                  płatkami złota
                </li>
                <li> Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 3</div>
            <div className="day-plan">
              <ul>
                <li>
                  Obiad w restauracji z menu z rybami bałtyckimi na Targu Rybnym
                </li>
                <li>Transfer na lotnisko / dworzec PKP</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
        <div className=" prices">
          <div>
            CENA OD: 450 zł / za osobę
            <p> CENA ZAWIERA</p>
            <ul>
              <li>Transfer </li>
              <li>Zakwaterowanie </li>
              <li>Towarzystwo przewodnika turystycznego </li>
            </ul>
          </div>
          <div>
            Cena zależy od ilości osób, programu i standardu noclegu. Cenę
            kalkulujemy dla każdego klienta indywidualnie, ponieważ zależy ona
            od ilości osób, programu i rodzaju noclegu. Każdy program
            dostosowany jest do życzeń naszych gości oraz do ich harmonogramu
            podróży.
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default KulinarnyPage
